
import { onMounted, defineComponent } from 'vue'
export default defineComponent({
  setup() {
    onMounted(() => {
      // add any cookie that has to be categorized by OneTrust cookie scanner
      document.cookie = 'dw_Technical_cookie=opt-in;'
      document.cookie = 'dw_Advertisement_cookie=opt-in;'
    })
  },
})
